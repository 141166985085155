import { useEffect } from "react";
import styled from "styled-components";
import Head from "next/head";
import styles from "../styles/Home.module.css";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

import FeaturedCard from "components/FeaturedCard";
import FeaturedCardSecondary from "components/FeaturedCardSecondary";
import SquareCard from "components/SquareCard";
import { request } from "utils/request";

const RegionCard = dynamic(() => import("components/RegionCard"));
const BigSliderCard = dynamic(() => import("components/BigSliderCard"));

interface Props {
  cmsData: any;
  deviceType: string;
}

const Home = ({ cmsData, deviceType }: Props) => {
  const router = useRouter();

  useEffect(() => {
    deepLinkHandler();
  }, []);

  const deepLinkHandler = () => {
    if (router?.asPath?.includes("camp")) {
      const splittedPage = router.asPath.split("/");
      router.push(`/kamp-alani/${splittedPage?.[2]}`);
    } else if (router?.asPath?.includes("blog")) {
      const splittedPage = router.asPath.split("/");
      router.push(`/makale/${splittedPage?.[2]}`);
    } else if (router?.asPath?.includes("event")) {
      const splittedPage = router.asPath.split("/");
      router.push(`/etkinlik/${splittedPage?.[2]}`);
    }
  };

  const renderCmsComponents = () => {
    return cmsData
      ?.sort((a: any, b: any) => a.sort - b.sort)
      .map((cms: any, index: number) => {
        if (!cms.title?.includes('Wolfest')) {
          if (cms.type === "featureCard") {
            return <FeaturedCard cmsData={cms} key={index} />;
          } else if (cms.type === "swiperCard") {
            return <SquareCard cmsData={cms} key={index} />;
          } else if (cms.type === "sliderCard") {
            return <BigSliderCard cmsData={cms} key={index} />;
          } else return null;
        }
        
      });
  };

  const scrollToBottom = () => {
    const treshold = window.innerWidth > 780 ? 50 : 100;
    window.scrollTo({ top: window.innerHeight - treshold, behavior: "smooth" });
  };

  const siteTitle = `Kampp - Kampp'la Planla! | #kampplaplanla`;

  return (
    <div className={styles.container}>
      <Head>
        <title>{siteTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="title"
          property="og:title"
          content="Kampp | Kamppla Planla!"
        />
        <meta
          name="description"
          property="og:description"
          content={
            "Kamp ,Türkiye’deki kamp yerleri ve doğa evleri için kapsamlı bir doğa rehberidir. Çadır kampını, karavan parklarını, kulübeleri, ağaç evleri ve glamping'i keşfedin."
          }
        ></meta>
        <meta name="url" property="og:url" content={"https://kampp.in"} />
        <meta
          name="image"
          property="og:image"
          content={"https://kampp.in/images/Main.webp"}
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content={siteTitle} />
        <meta
          property="twitter:description"
          content={
            "Kamp ,Türkiye’deki kamp yerleri ve doğa evleri için kapsamlı bir doğa rehberidir. Çadır kampını, karavan parklarını, kulübeleri, ağaç evleri ve glamping'i keşfedin. Diğer kullanıcılarla deneyimlerinizi paylaşın, yol tarifi alın, doğa ile ilgili makaleler okuyarak bilgi edinin!"
          }
        ></meta>
        <meta property="twitter:url" content={"https://kampp.in"} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:url" content={"https://kampp.in"} />
        <meta property="og:type" content={"website"} />
        <meta
          property="og:description"
          content={
            "Kamp ,Türkiye’deki kamp yerleri ve doğa evleri için kapsamlı bir doğa rehberidir. Çadır kampını, karavan parklarını, kulübeleri, ağaç evleri ve glamping'i keşfedin."
          }
        ></meta>
        <meta
          property="og:image"
          content={"https://kampp.in/images/Main.webp"}
        />
      </Head>

      <FeatureContainer>
        <CoverImage>
          <CoverButtonContainer>
            <CoverTitle>
              Nereye gitmek istediğinize karar veremediniz mi?
            </CoverTitle>
            <Link href="/kamp-alanlari" passHref>
              <CoverButton>Kamppla Planla!</CoverButton>
            </Link>
          </CoverButtonContainer>
        </CoverImage>
      </FeatureContainer>

      <IconContainer onClick={scrollToBottom}>
        <Icon icon={faAngleDoubleDown} />
      </IconContainer>

      <ContentContainer>
        {/* <FeaturedCardSecondary deviceType={deviceType} /> */}
        <RegionCard />
        {renderCmsComponents()}
      </ContentContainer>
    </div>
  );
};

const FeatureContainer = styled.div`
  background-color: black;
  padding-bottom: 48px;
`;

const CoverImage = styled.div`
  background-image: url(/images/Main.webp);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: calc(100vh - 250px);
  padding: 40px 40px;
  margin: 0px 10% 30px;
`;

const CoverButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    margin-bottom: 0px;
  }
`;

const CoverTitle = styled.h1`
  font-size: 40px;
  font-weight: 500;
  color: white;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    font-size: 36px;
  }
`;

const CoverButton = styled.div`
  cursor: pointer;
  align-self: center;
  background-color: white;
  border-radius: 32px;
  padding: 15px 30px;
  font-size: 16px;
  color: black;
`;

const ContentContainer = styled.div`
  margin: 96px 0px;
  padding: 0px 15%;
  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    padding: 0px 5%;
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    padding: 0px 30px;
  }
`;

const IconContainer = styled.div`
  @keyframes updown {
    0% {
      transform: translateY(-10%);
    }
    50% {
      transform: translateY(30%);
    }
    100% {
      transform: translateY(-10%);
    }
  }
  position: absolute;
  right: 50px;
  top: 90vh;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.main.primary};
  cursor: pointer;
  animation: updown 2s ease-out infinite;
  @media (max-width: ${(props) => props.theme.size.laptop}) {
    top: 85vh;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  width: 30px;
  height: 30px;
  color: ${(props) => props.theme.main.white};
`;

export async function getServerSideProps(context: any) {
  const UA = context.req.headers['user-agent'];
  const isMobile = Boolean(UA.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  ))

  const { data }: any = await request("explore/list", "GET");
  return {
    props: {
      cmsData: data,
      deviceType: isMobile ? 'mobile' : 'desktop'
    },
  };
}

export default Home;
