import styled from "styled-components";
import Link from 'next/link'

interface ImageInput {
  url: string;
}

const FeaturedCard = ({ cmsData }: any) => {
const redirectUrl = cmsData.isExternal ? cmsData.externalUrl : `/kamp-alanlari?cms=${cmsData.id}`
  
  return (
    <Container>
      <Title>{cmsData.title}</Title>
      <CoverImage url={cmsData.image}>
        <CoverButtonContainer>
          <CoverTitle>{cmsData.description}</CoverTitle>
          <Link href={redirectUrl} passHref>
            <SLink target={cmsData.isExternal ? "_blank": '_parent'} rel="noopener">
              <CoverButton>{cmsData.buttonText}</CoverButton>
            </SLink>
          </Link>
        </CoverButtonContainer>
      </CoverImage>
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  margin-bottom: 96px;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 600;
  font-weight: 600;
  text-align: left;
`;

const CoverImage = styled.div<ImageInput>`
  background-image: ${(props) => `url(${props.url})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 40px 40px;
  margin-top: 24px;
  height: 500px;
  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    height: 400px;
  }
`;

const CoverButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const CoverTitle = styled.h1`
  font-weight: 500;
  color: white;
`;

const CoverButton = styled.div`
  cursor: pointer;
  align-self: center;
  background-color: white;
  border-radius: 32px;
  padding: 15px 30px;
  font-size: 16px;
  color: black;
`;

const SLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default FeaturedCard;
