import styled from "styled-components";
import Link from "next/link";

const SquareCard = ({ cmsData }: any) => {
  return (
    <Container>
      <Title>{cmsData.title}</Title>
      <CardContainer>
        {cmsData.camp.slice(0, 3).map((c: any, index: number) => (
          <Link href={`/kamp-alani/${c.slug}`} passHref key={index}>
            <Card>
              <CardImage
                loading="lazy"
                src={c.imagePaths?.[0]}
                alt={c.title}
                width={500}
                height={500}
              />
              <CardContent>
                <CardTitle>{c.title}</CardTitle>
                <CardDescription>
                  {c.city} / {c.county}
                </CardDescription>
              </CardContent>
            </Card>
          </Link>
        ))}
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  margin-bottom: 96px;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 600;
  font-weight: 600px;
  text-align: left;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justift-content: space-between;
  align-items: center;
  margin-top: 24px;
  @media (max-width: ${(props) => props.theme.size.laptop}) {
    flex-wrap: wrap;
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  padding: 5px 0px;
  flex: 1;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  margin-right: 30px;
  // &:last-child {
  //   margin-right: 0px;
  // }
  @media (max-width: ${(props) => props.theme.size.laptop}) {
    width: 45%;
    // &:nth-child(2) {
    //   margin-right: 0px;
    // }
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 100%;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.size.laptop}) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 100%;
    height: 350px
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100px;
  background-color: white;
  padding: 20px 16px 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    width: 100%;
  }
`;

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #132005;
  padding-bottom: 5px;
  width: 100%;
`;

const CardDescription = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #28400c;
`;

export default SquareCard;
